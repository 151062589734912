/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import parse from 'html-react-parser'

const GetPost = () => {
  const [errorMsg, setErrorMsg] = useState(null)
  const searchParams = new URLSearchParams(document.location.search)
  const postId = searchParams.get('id')
  const [post, setPost] = useState([])

  useEffect(() => {
    if (!postId) {
      return setErrorMsg('No Post ID passed in')
    }
    async function loadPost () {
      const response = await fetch(`https://innovation.consumerreports.org/wp-json/wp/v2/posts/${postId}?_embed`)
      if (!response.ok) {
        return setErrorMsg(`Could not find a page with post ID ${postId}`)
      }
      const post = await response.json()

      // Set canonical url (https://developers.google.com/search/docs/crawling-indexing/javascript/javascript-seo-basics#properly-inject-canonical-links)
      const linkTag = document.createElement('link')
      linkTag.setAttribute('rel', 'canonical')
      linkTag.href = post.link
      document.head.appendChild(linkTag)

      document.title = parse(post.title.rendered)
      document.querySelector('meta[property="og:title"]').setAttribute('content', post.title.rendered.replace(/(<([^>]+)>)/ig, ''))
      document.querySelector('meta[name="twitter:title"]').setAttribute('content', post.title.rendered.replace(/(<([^>]+)>)/ig, ''))
      document.querySelector('meta[name="description"]').setAttribute('content', post.excerpt.rendered.replace(/(<([^>]+)>)/ig, ''))
      document.querySelector('meta[property="og:description"]').setAttribute('content', post.excerpt.rendered.replace(/(<([^>]+)>)/ig, ''))
      document.querySelector('meta[name="twitter:description"]').setAttribute('content', post.excerpt.rendered.replace(/(<([^>]+)>)/ig, ''))

      setPost(post)
    }
    loadPost()
  }, [])

  if (errorMsg) return <div>{errorMsg}</div>

  if (!post || post.length === 0) return <div>Loading</div>

  const getAuthor = (author) => {
    if (!author || !author[0]) return
    return author[0].name
  }

  return (
    <article key={post.id} className='post-content'>
      <div className='meta'>
        <div className="byline mb-4 font-sans3 text-primary-700">
          <time pubdate={post.date} dateTime={post.date} title={format(new Date(post.date), 'MMMM do, yyyy')}>{format(new Date(post.date), 'MMM d, yyyy')}</time>
          <span className='mx-2'>|</span>
          <address className="author inline not-italic">{getAuthor(post._embedded.author)}</address>
        </div>
      </div>
      <h2 className='title pb-8 border-b-4 border-primary-200 leading-snug'>{parse(post.title.rendered)}</h2>
      <div className='content prose max-w-none' >{parse(post.content.rendered)}</div>
    </article>
  )
}

GetPost.propTypes = {}

export default GetPost
