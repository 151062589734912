import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import parse from 'html-react-parser'

const Caret = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 400.3 673">
      <polygon points="338.7,278.4 60.3,0 0,60.3 278.4,338.7 5.7,611.4 67.3,673 340,400.3 400.3,340 "/>
    </svg>
  )
}

const GetAllPosts = ({ tagid, featuredtagid, nbr, baseurl, page }) => {
  const [noFeaturedPosts, setNoFeaturedPosts] = useState(false)
  const [featuredPost, setFeaturedPost] = useState()
  const [posts, setPosts] = useState()

  useEffect(() => {
    async function loadFeaturedPost () {
      const response = await fetch(`https://innovation.consumerreports.org/wp-json/wp/v2/posts?tags=${featuredtagid}&_embed&per_page=1`)
      if (!response.ok) return // oups! something went wrong
      const post = await response.json()
      if (post.length > 0) {
        setFeaturedPost(post.length > 0 ? post[0] : -1)
      } else {
        setNoFeaturedPosts(true)
      }
    }
    if (!featuredtagid) return setNoFeaturedPosts(true)
    loadFeaturedPost()
  }, [])

  useEffect(() => {
    if (!featuredPost && !noFeaturedPosts) return // Featured post hasn't loaded yet.
    async function loadPosts () {
      const response = await fetch(`https://innovation.consumerreports.org/wp-json/wp/v2/posts?tags=${tagid}&_embed&exclude=${noFeaturedPosts ? -1 : featuredPost.id}&per_page=${nbr > 0 ? nbr : 100}`)
      if (!response.ok) return // oups! something went wrong
      const posts = await response.json()
      if (featuredtagid && noFeaturedPosts) setFeaturedPost(posts.shift()) // If there are no featured posts, take the first item from posts
      setPosts(posts)
    }
    if (!posts) {
      loadPosts()
    }
  }, [featuredPost, noFeaturedPosts])

  const getAuthor = (author) => {
    if (!author || !author[0]) return
    return author[0].name
  }

  return (
    <>
    {featuredtagid &&
    <section className="relative my-0 pt-10">
      <div className="absolute top-0 w-full h-1/2 -z-10 bg-gradient-matrix-1"></div>

        <article className="container" aria-labelledby="featured-article-title">
          <div className="bg-white shadow-lg p-8 lg:px-20">
            <div className="sm:flex sm:justify-between sm:items-end  pb-4 gap-8 sm:border-b-4 sm:border-primary-200">
              <div className="text-4xl sm:text-5xl font-sans2 mb-6 pb-6 sm:mb-0 sm:pb-0 border-b-4 border-primary-200 sm:border-0">Featured Post</div>

                <div className="byline font-sans3 flex-1 mb-1 text-primary-700 text-sm sm:text-right xl:whitespace-nowrap">
                  {featuredPost
                    ? <>
                    <time dateTime={featuredPost.date} >{format(new Date(featuredPost.date), 'MMMM d, yyyy')}</time>
                    <span aria-hidden="true" className='mx-2'>|</span>
                    <address rel="author" className="author inline not-italic">{getAuthor(featuredPost._embedded.author)}</address>
                    </>
                    : <>
                    <span className="skeleton skeleton-text skeleton-text__meta "></span>
                    <span aria-hidden="true" className='mx-2'>|</span>
                    <span className="skeleton skeleton-text skeleton-text__meta "></span>
                    </>
                  }
                </div>

            </div>

            <div className="sm:flex pt-0 sm:pt-8 gap-x-16">
              <div className="w-full sm:w-1/2" data-xposdelay="true">
                {featuredPost
                  ? <a href={`${baseurl}/update/?id=${featuredPost.id}`} className='has-link-arrow text-secondary no-underline'>
                    <h2 id="featured-article-title" className='title line-clamp-3 text-2xl sm:text-3xl text-copy'>{parse(featuredPost.title.rendered)}</h2>
                  </a>
                  : <>
                  <div className="skeleton skeleton-text skeleton-text__title"></div>
                  <div className="skeleton skeleton-text skeleton-text__title"></div>
                  </>
                }
              </div>
              <div className="w-full sm:w-1/2" data-xposdelay="true">
                {featuredPost
                  ? <>
                  <div className='content line-clamp-4' >{parse(featuredPost.excerpt.rendered)}</div>
                  <a href={`${baseurl}/update/?id=${featuredPost.id}`} className='has-link-arrow text-secondary font-sans3 text-sm no-underline mt-4 inline-block'>Continue Reading<Caret /></a>
                  </>
                  : <>
                  <div className="skeleton skeleton-text skeleton-text__body"></div>
                  <div className="skeleton skeleton-text skeleton-text__body"></div>
                  <div className="skeleton skeleton-text skeleton-text__body"></div>
                  <div className="skeleton skeleton-text skeleton-text__body"></div>
                  </>
                }
              </div>
            </div>

          </div>
        </article>

    </section>
    }
    <section className="mt-12">
      <div className="container">
        <div className="row">
          {posts && posts.length > 0
            ? <div className="col-12">
              <div className="updates-list grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-10">
                {posts.map((post, index) => {
                  return (
                    <article key={post.id} id={post.id} className='p-8 bg-white' aria-labelledby={`title-${post.id}`}>
                      <div className='meta'>
                        <div className="byline mb-2 text-primary-700 text-sm font-sans3">
                          <time dateTime={post.date} className="whitespace-nowrap">{format(new Date(post.date), 'MMMM d, yyyy')}</time>
                          <span aria-hidden="true" className='mx-2'>|</span>
                          <address rel="author"className="author inline not-italic">{getAuthor(post._embedded.author)}</address>
                        </div>
                      </div>
                      <a href={`${baseurl}/update/?id=${post.id}`} className='text-copy no-underline hover:underline'>
                        <h2 id={`title-${post.id}`} className='h4 text-2xl title line-clamp-3'>{parse(post.title.rendered)}</h2>
                      </a>

                      <div className='content line-clamp-4' >{parse(post.excerpt.rendered)}</div>

                      <a href={`${baseurl}/update/?id=${post.id}`} className='has-link-arrow text-secondary font-sans3 text-sm no-underline mt-4 block'>Continue Reading<Caret /></a>
                    </article>
                  )
                })}
              </div>
            </div>
            : <div>Loading</div>
          }
        </div>
      </div>
    </section>
    </>
  )
}

GetAllPosts.propTypes = {
  tagid: PropTypes.string,
  featuredtagid: PropTypes.string,
  nbr: PropTypes.string,
  baseurl: PropTypes.string,
  page: PropTypes.string
}

export default GetAllPosts
