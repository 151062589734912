let scrollPosition = window.scrollY

let lastScroll = -1
window.addEventListener('scroll', function () {
  scrollPosition = window.scrollY
  // if (scrollPosition >= 30) {
  //   document.body.classList.add('scrolled');
  // } else {
  //   document.body.classList.remove('scrolled');
  // }

  if (scrollPosition >= 96 && lastScroll - scrollPosition < 0) {
    document.body.classList.add('scrolling-down')
    document.body.classList.remove('scrolling-up')
  } else {
    document.body.classList.add('scrolling-up')
    document.body.classList.remove('scrolling-down')
  }
  lastScroll = scrollPosition
})
