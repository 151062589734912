import React, { useState } from 'react'

const Contact = () => {
  const searchParams = new URLSearchParams(document.location.search)
  const subject = searchParams.get('subject')
  const [email, setEmail] = useState()
  const subjects = [
    'Learn more about the Data Rights Protocol',
    'Interested in the Data Rights Protocol consortium',
    'Implementing the Data Rights Protocol'
  ]
  const isValidEmail = (email) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    console.log(email)
    return regex.test(email)
  }
  return (

    <div id="mc_embed_shell">

      <div id="mc_embed_signup">
        <form action="https://crnet.us2.list-manage.com/subscribe/post?u=2383cf2be56ebef644c6a3bcd&amp;id=141499b39c&amp;f_id=00a550e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8" >
            <div className="mb-4 md:mb-0">

              <div className="form-group">
                <label htmlFor="mce-FNAME">First Name</label>
                <input type="text" name="FNAME" id="mce-FNAME" />
              </div>
              <div className="form-group">
                <label htmlFor="mce-LNAME">Last Name</label>
                <input type="text" name="LNAME" id="mce-FNAME" />
              </div>
              <div className="form-group">
                <label htmlFor="mce-EMAIL">Email</label>
                <input type="text" name="EMAIL" id="mce-EMAIL" onChange={(e) => { setEmail(e.target.value) }}required/>
              </div>
              <div className="form-group">
                <label htmlFor="mce-MMERGE5">Subject Line</label>
                <select name="MMERGE5" id="mce-MMERGE5">
                  { subjects.map((subject, i) => {
                    return <option key={i} value={subject}>{subject}</option>
                  })}
                </select>
              </div>
            </div>

            <div>
              <div className="form-group flex flex-col md:h-full">
                <label htmlFor="mce-MMERGE6">Message </label>
                <textarea name="MMERGE6" className="h-48 md:h-full" id="mce-MMERGE6"></textarea>
              </div>
              <div className="mt-2 md:ml-auto md:mr-0 leading-tight">
                We care about the protection of your data. Read our <a href="https://www.consumerreports.org/cro/customerservice/privacy-policy/highlights/index.htm" className="text-black" target="_blank" rel="noreferrer">Privacy Policy</a>
              </div>
            </div>

          </div>

          <div id="mce-responses" className="clear">
            <div className="response hidden" id="mce-error-response"></div>
            <div className="response hidden" id="mce-success-response"></div>
          </div>
          <div aria-hidden="true" className="absolute left-[-5000px]">
            <input type="text" name="b_2383cf2be56ebef644c6a3bcd_141499b39c" tabIndex="-1" />
          </div>
          <div className="clear">
            <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="btn" defaultValue="Subscribe" disabled={!isValidEmail(email)}/>
          </div>
        </form>
      </div>

      <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>

    </div>

  )
}

export default Contact
