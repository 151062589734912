import React from 'react'
import { createRoot } from 'react-dom/client'
import GetPost from './GetPost.js'
import GetAllPosts from './GetAllPosts.js'
import Contact from './Contact.js'

const COMPONENTS = {
  GetPost,
  GetAllPosts,
  Contact
}

export default function renderComponentInElement (el) {
  const Component = COMPONENTS[el.dataset.component]
  if (!Component) return

  const props = Object.assign({}, el.dataset)
  const root = createRoot(el) // createRoot(container!) if you use TypeScript
  root.render(<Component {...props} />)
}

document
  .querySelectorAll('.__react-component')
  .forEach(renderComponentInElement)
