// init controller
// eslint-disable-next-line no-undef
const controller = new ScrollMagic.Controller()

const fadeInUps = document.querySelectorAll("*[class*='animate-']")

for (let i = 0; i < fadeInUps.length; i++) {
  let delay = fadeInUps[i].getAttribute('data-delay')

  const xPosDelay = fadeInUps[i].getAttribute('data-xposdelay')

  if (xPosDelay) {
    const rect = fadeInUps[i].getBoundingClientRect()
    const percentAcrossScreen = rect.left / window.innerWidth
    delay = 1 * percentAcrossScreen
  }

  // build scenes
  // eslint-disable-next-line no-undef
  new ScrollMagic.Scene({
    triggerElement: fadeInUps[i],
    triggerHook: 0.9, // show, when scrolled 10% into view
    reverse: false,
    delay
  })
    .setTween(
      fadeInUps[i], 1, { opacity: 1, x: 0, y: 0, scale: 1, delay })
    // .setClassToggle(fadeInUps[i], "visible") // add class toggle
    // .addIndicators() // add indicators (requires plugin)
    .addTo(controller)
}
