const lists = document.querySelectorAll('.shuffle-children')
for (let i = 0; i < lists.length; i++) {
  const ul = lists[i]
  const listLength = ul.children.length

  // Shuffle the list
  for (let ii = listLength; ii >= 0; ii--) {
    ul.appendChild(ul.children[Math.random() * ii | 0])
  }

  // duplicate the items in the list.
  for (let ii = 0; ii < listLength; ii++) {
    const cln = ul.children[ii].cloneNode(true)
    cln.setAttribute('aria-hidden', true)
    ul.appendChild(cln)
  }

  ul.classList.add('shuffled')
}
